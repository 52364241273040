<template>
    <div>
        <!-- :style="backgroundColorStyle" -->
        <v-data-table
        class="tableHeaderClass"
        :loading="isDataLoading"
        :headers="tableTitle"
        :items="tableItem"
        :options.sync="getDataFromAPI"
        :items-per-page="10"
        :footer-props="{
            'disable-items-per-page' : true
        }"
        >
        <template v-for="customeObj in customeSlotName" v-slot:[`item.${customeObj.headerValue}`]="{item}" class="actionClass">
            <td class="actionClass" v-if="customeObj.ComponenetType == 'button'">
                <div v-for="actionItem in customeObj.objectToRender" >
                    <v-btn class="mx-0" icon @click="actionPerformed(actionItem.functionToEmit, item)">
                        <img :src=actionItem.iconSrc />
                    </v-btn>
                </div>
            </td>
            <td class="actionClass" v-if="customeObj.ComponenetType == 'vSwitch'">
                <div v-for="actionItem in customeObj.objectToRender" >
                    <v-switch v-model="item.availableStatus" @click="switchPerformed(actionItem.functionToEmit, item)"></v-switch>
                </div>
            </td>
            <td class="actionClass" v-if="customeObj.ComponenetType == 'vSelect'">
                <V-select
                dense
                style="width: 60px;"
                v-model="item.actionValue"
                :items="customeObj.objectToRender.optionList"
                :item-text="customeObj.objectToRender.optionText"
                :item-value=customeObj.objectToRender.optionValue
                return-object
                @change="statusChanged(item.actionValue, item)"
                ></v-select>
            </td>
        </template>
        </v-data-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            slotName: 'actions',
            selectedDropdownValue:'',
            getDataFromAPI: null,
            endOfData: null,
            switchModel: false,
        }
    },
    props: {
        tableTitle: {
            type: Array,
            requied: true
        },
        tableItem: {
            type: Array,
            requied: true
        },
        customeSlotName: {
            type: Array,
            requied: false
        },
        isDataLoading: {
            type:Boolean, 
            requied: true
        },
        actionClicked: {
            type: Function,
            requied: false
        },
        selectFunctionEmited: {
            type: Function,
            requied: false
        },
        paginationEmitted: {
            type: Function,
            requied: false
        }, 
        endOfDataObject: {
            type: Boolean,
            requied: true
        }
    },
    watch: {
        getDataFromAPI (){
            if(this.endOfDataObject){
                this.getData()
            }
            deep: true
        }
    },
    mounted() {
    },
    methods: {
        updateHeroBannerRecord() {
            console.log('updateHeroBannerRecord')
        },
        actionPerformed(functionName, rowObject){
            this.$emit("actionClicked", functionName, rowObject)
        },
        switchPerformed(functionName, rowObject){
            this.$emit("switchClicked", functionName, rowObject)
        },
        statusChanged(statusValue, offerCode){
            this.$emit("selectFunctionEmited", statusValue.statusValue, offerCode.offer_code)
        },
        getData(){
            this.$emit("paginationEmitted")
        },
    },
}
</script>
<style scoped>
.actionClass{
    display: flex !important;
    flex-direction: row !important;
}
</style>
<style>
.tableHeaderClass .v-data-table-header th{
    background: #1467bf !important;
    /* #F4F6FA !important; */
    color: #FFFFFF !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
  }
</style>


<!-- //custome Table support multiple button and one v-select, v-switch filed per header colum
The object should in the follwing template 
devDefinedObject : [
{
    'headerValue':'valueOfHeader',
    'ComponenetType': 'button', // allowed types are (button)
    'objectToRender': [
        {
            'iconSrc' : "https://s3iconimages.mymedicine.com.mm/edit.svg", // img src for icon
            'functionToEmit': 'functionName' // function to be called on parent page 

        },
        {
            'iconSrc' : "https://s3iconimages.mymedicine.com.mm/family_delete.svg",
            'functionToEmit': 'functionName'

        }
    ],
},
{
    'headerValue':'valueOfHeader',
    'ComponenetType':'vSelect', //V-select will be rendered 
    'functionName': 'functionName' // function name to be called on parent page on slection
},
{
        'headerValue':'valueOfHeader',
        'ComponenetType':'vSwitch', // v-switch will be rendered
        'functionName': 'functionName' // funciton name to be called on parent page on switching
}
] -->